export default {
    site_name: "MW Participants", 
    roles: "participants",
    role: "participant",
    email: "research@m.works",
    address: "18 St. Cross Street. London, EC1N 8UN",
    colors: ["primary", "secondary", "darkblue",  "lightblue", "pink", "yellow", "green" ],
    prefixes: [
        { text: "+93 Afghanistan", value: "+93" },
        { text: "+355 Albania", value: "+355" },
        { text: "+213 Algeria", value: "+213" },
        { text: "+1 American Samoa", value: "+1" },
        { text: "+376 Andorra", value: "+376" },
        { text: "+244 Angola", value: "+244" },
        { text: "+1 Anguilla", value: "+1" },
        { text: "+1 Antigua and Barbuda", value: "+1" },
        { text: "+54 Argentina", value: "+54" },
        { text: "+374 Armenia", value: "+374" },
        { text: "+297 Aruba", value: "+297" },
        { text: "+61 Australia", value: "+61" },
        { text: "+672 Australian External Territories", value: "+672" },
        { text: "+43 Austria", value: "+43" },
        { text: "+994 Azerbaijan", value: "+994" },
        { text: "+1 Bahamas", value: "+1" },
        { text: "+973 Bahrain", value: "+973" },
        { text: "+880 Bangladesh", value: "+880" },
        { text: "+1 Barbados", value: "+1" },
        { text: "+375 Belarus", value: "+375" },
        { text: "+32 Belgium", value: "+32" },
        { text: "+501 Belize", value: "+501" },
        { text: "+229 Benin", value: "+229" },
        { text: "+1 Bermuda", value: "+1" },
        { text: "+975 Bhutan", value: "+975" },
        { text: "+591 Bolivia (Plurinational State of)", value: "+591" },
        { text: "+599 Bonaire, Sint Eustatius and Saba", value: "+599" },
        { text: "+387 Bosnia and Herzegovina", value: "+387" },
        { text: "+267 Botswana", value: "+267" },
        { text: "+55 Brazil", value: "+55" },
        { text: "+1 British Virgin Islands", value: "+1" },
        { text: "+673 Brunei Darussalam", value: "+673" },
        { text: "+359 Bulgaria", value: "+359" },
        { text: "+226 Burkina Faso", value: "+226" },
        { text: "+257 Burundi", value: "+257" },
        { text: "+855 Cambodia", value: "+855" },
        { text: "+237 Cameroon", value: "+237" },
        { text: "+1 Canada", value: "+1" },
        { text: "+238 Cape Verde", value: "+238" },
        { text: "+1 Cayman Islands", value: "+1" },
        { text: "+236 Central African Rep.", value: "+236" },
        { text: "+235 Chad", value: "+235" },
        { text: "+56 Chile", value: "+56" },
        { text: "+86 China", value: "+86" },
        { text: "+57 Colombia", value: "+57" },
        { text: "+269 Comoros", value: "+269" },
        { text: "+242 Congo", value: "+242" },
        { text: "+682 Cook Islands", value: "+682" },
        { text: "+506 Costa Rica", value: "+506" },
        { text: "+225 Côte d'Ivoire", value: "+225" },
        { text: "+385 Croatia", value: "+385" },
        { text: "+53 Cuba", value: "+53" },
        { text: "+599 Curaçao", value: "+599" },
        { text: "+357 Cyprus", value: "+357" },
        { text: "+420 Czech Rep.", value: "+420" },
        { text: "+850 Dem. People's Rep. of Korea", value: "+850" },
        { text: "+243 Dem. Rep. of the Congo", value: "+243" },
        { text: "+45 Denmark", value: "+45" },
        { text: "+246 Diego Garcia", value: "+246" },
        { text: "+253 Djibouti", value: "+253" },
        { text: "+1 Dominica", value: "+1" },
        { text: "+1 Dominican Rep.", value: "+1" },
        { text: "+593 Ecuador", value: "+593" },
        { text: "+20 Egypt", value: "+20" },
        { text: "+503 El Salvador", value: "+503" },
        { text: "+240 Equatorial Guinea", value: "+240" },
        { text: "+291 Eritrea", value: "+291" },
        { text: "+372 Estonia", value: "+372" },
        { text: "+251 Ethiopia", value: "+251" },
        { text: "+500 Falkland Islands (Malvinas)", value: "+500" },
        { text: "+298 Faroe Islands", value: "+298" },
        { text: "+679 Fiji", value: "+679" },
        { text: "+358 Finland", value: "+358" },
        { text: "+33 France", value: "+33" },
        {
            text: "+262 French Dep. and Territories in the Indian Ocean",
            value: "+262",
        },
        { text: "+594 French Guiana", value: "+594" },
        { text: "+689 French Polynesia", value: "+689" },
        { text: "+241 Gabon", value: "+241" },
        { text: "+220 Gambia", value: "+220" },
        { text: "+995 Georgia", value: "+995" },
        { text: "+49 Germany", value: "+49" },
        { text: "+233 Ghana", value: "+233" },
        { text: "+350 Gibraltar", value: "+350" },
        {
            text: "+881 Global Mobile Satellite System (GMSS), shared",
            value: "+881",
        },
        { text: "+30 Greece", value: "+30" },
        { text: "+299 Greenland", value: "+299" },
        { text: "+1 Grenada", value: "+1" },
        { text: "+388 Group of countries, shared code", value: "+388" },
        { text: "+590 Guadeloupe", value: "+590" },
        { text: "+1 Guam", value: "+1" },
        { text: "+502 Guatemala", value: "+502" },
        { text: "+224 Guinea", value: "+224" },
        { text: "+245 Guinea-Bissau", value: "+245" },
        { text: "+592 Guyana", value: "+592" },
        { text: "+509 Haiti", value: "+509" },
        { text: "+504 Honduras", value: "+504" },
        { text: "+852 Hong Kong, China", value: "+852" },
        { text: "+36 Hungary", value: "+36" },
        { text: "+354 Iceland", value: "+354" },
        { text: "+91 India", value: "+91" },
        { text: "+62 Indonesia", value: "+62" },
        { text: "+870 Inmarsat SNAC", value: "+870" },
        { text: "+800 International Freephone Service", value: "+800" },
        { text: "+882 International Networks, shared code", value: "+882" },
        { text: "+883 International Networks, shared code", value: "+883" },
        {
            text: "+979 International Premium Rate Service (IPRS)",
            value: "+979",
        },
        {
            text: "+808 International Shared Cost Service (ISCS)",
            value: "+808",
        },
        { text: "+98 Iran (Islamic Republic of)", value: "+98" },
        { text: "+964 Iraq", value: "+964" },
        { text: "+353 Ireland", value: "+353" },
        { text: "+972 Israel", value: "+972" },
        { text: "+39 Italy", value: "+39" },
        { text: "+1 Jamaica", value: "+1" },
        { text: "+81 Japan", value: "+81" },
        { text: "+962 Jordan", value: "+962" },
        { text: "+7 Kazakhstan", value: "+7" },
        { text: "+254 Kenya", value: "+254" },
        { text: "+686 Kiribati", value: "+686" },
        { text: "+82 Korea (Rep. of)", value: "+82" },
        { text: "+965 Kuwait", value: "+965" },
        { text: "+996 Kyrgyzstan", value: "+996" },
        { text: "+856 Lao P.D.R.", value: "+856" },
        { text: "+371 Latvia", value: "+371" },
        { text: "+961 Lebanon", value: "+961" },
        { text: "+266 Lesotho", value: "+266" },
        { text: "+231 Liberia", value: "+231" },
        { text: "+218 Libya", value: "+218" },
        { text: "+423 Liechtenstein", value: "+423" },
        { text: "+370 Lithuania", value: "+370" },
        { text: "+352 Luxembourg", value: "+352" },
        { text: "+853 Macao, China", value: "+853" },
        { text: "+261 Madagascar", value: "+261" },
        { text: "+265 Malawi", value: "+265" },
        { text: "+60 Malaysia", value: "+60" },
        { text: "+960 Maldives", value: "+960" },
        { text: "+223 Mali", value: "+223" },
        { text: "+356 Malta", value: "+356" },
        { text: "+692 Marshall Islands", value: "+692" },
        { text: "+596 Martinique", value: "+596" },
        { text: "+222 Mauritania", value: "+222" },
        { text: "+230 Mauritius", value: "+230" },
        { text: "+52 Mexico", value: "+52" },
        { text: "+691 Micronesia", value: "+691" },
        { text: "+373 Moldova (Republic of)", value: "+373" },
        { text: "+377 Monaco", value: "+377" },
        { text: "+976 Mongolia", value: "+976" },
        { text: "+382 Montenegro", value: "+382" },
        { text: "+1 Montserrat", value: "+1" },
        { text: "+212 Morocco", value: "+212" },
        { text: "+258 Mozambique", value: "+258" },
        { text: "+95 Myanmar", value: "+95" },
        { text: "+264 Namibia", value: "+264" },
        { text: "+674 Nauru", value: "+674" },
        { text: "+977 Nepal", value: "+977" },
        { text: "+31 Netherlands", value: "+31" },
        { text: "+687 New Caledonia", value: "+687" },
        { text: "+64 New Zealand", value: "+64" },
        { text: "+505 Nicaragua", value: "+505" },
        { text: "+227 Niger", value: "+227" },
        { text: "+234 Nigeria", value: "+234" },
        { text: "+683 Niue", value: "+683" },
        { text: "+1 Northern Marianas", value: "+1" },
        { text: "+47 Norway", value: "+47" },
        { text: "+968 Oman", value: "+968" },
        { text: "+92 Pakistan", value: "+92" },
        { text: "+680 Palau", value: "+680" },
        { text: "+507 Panama", value: "+507" },
        { text: "+675 Papua New Guinea", value: "+675" },
        { text: "+595 Paraguay", value: "+595" },
        { text: "+51 Peru", value: "+51" },
        { text: "+63 Philippines", value: "+63" },
        { text: "+48 Poland", value: "+48" },
        { text: "+351 Portugal", value: "+351" },
        { text: "+1 Puerto Rico", value: "+1" },
        { text: "+974 Qatar", value: "+974" },
        { text: "+40 Romania", value: "+40" },
        { text: "+7 Russian Federation", value: "+7" },
        { text: "+250 Rwanda", value: "+250" },
        {
            text: "+247 Saint Helena, Ascension and Tristan da Cunha",
            value: "+247",
        },
        {
            text: "+290 Saint Helena, Ascension and Tristan da Cunha",
            value: "+290",
        },
        { text: "+1 Saint Kitts and Nevis", value: "+1" },
        { text: "+1 Saint Lucia", value: "+1" },
        { text: "+508 Saint Pierre and Miquelon", value: "+508" },
        { text: "+1 Saint Vincent and the Grenadines", value: "+1" },
        { text: "+685 Samoa", value: "+685" },
        { text: "+378 San Marino", value: "+378" },
        { text: "+239 Sao Tome and Principe", value: "+239" },
        { text: "+966 Saudi Arabia", value: "+966" },
        { text: "+221 Senegal", value: "+221" },
        { text: "+381 Serbia", value: "+381" },
        { text: "+248 Seychelles", value: "+248" },
        { text: "+232 Sierra Leone", value: "+232" },
        { text: "+65 Singapore", value: "+65" },
        { text: "+1 Sint Maarten (Dutch part)", value: "+1" },
        { text: "+421 Slovakia", value: "+421" },
        { text: "+386 Slovenia", value: "+386" },
        { text: "+677 Solomon Islands", value: "+677" },
        { text: "+252 Somalia", value: "+252" },
        { text: "+27 South Africa", value: "+27" },
        { text: "+211 South Sudan", value: "+211" },
        { text: "+34 Spain", value: "+34" },
        { text: "+94 Sri Lanka", value: "+94" },
        { text: "+249 Sudan", value: "+249" },
        { text: "+597 Suriname", value: "+597" },
        { text: "+268 Swaziland", value: "+268" },
        { text: "+46 Sweden", value: "+46" },
        { text: "+41 Switzerland", value: "+41" },
        { text: "+963 Syrian Arab Republic", value: "+963" },
        { text: "+886 Taiwan, China", value: "+886" },
        { text: "+992 Tajikistan", value: "+992" },
        { text: "+255 Tanzania", value: "+255" },
        {
            text: "+888 Telecommunications for Disaster Relief (TDR)",
            value: "+888",
        },
        { text: "+66 Thailand", value: "+66" },
        {
            text: "+389 The Former Yugoslav Republic of Macedonia",
            value: "+389",
        },
        { text: "+670 Timor-Leste", value: "+670" },
        { text: "+228 Togo", value: "+228" },
        { text: "+690 Tokelau", value: "+690" },
        { text: "+676 Tonga", value: "+676" },
        {
            text: "+991 Trial of a proposed new international service",
            value: "+991",
        },
        { text: "+1 Trinidad and Tobago", value: "+1" },
        { text: "+216 Tunisia", value: "+216" },
        { text: "+90 Turkey", value: "+90" },
        { text: "+993 Turkmenistan", value: "+993" },
        { text: "+1 Turks and Caicos Islands", value: "+1" },
        { text: "+688 Tuvalu", value: "+688" },
        { text: "+256 Uganda", value: "+256" },
        { text: "+380 Ukraine", value: "+380" },
        { text: "+971 United Arab Emirates", value: "+971" },
        { text: "+44 United Kingdom", value: "+44" },
        { text: "+1 United States", value: "+1" },
        { text: "+1 United States Virgin Islands", value: "+1" },
        {
            text: "+878 Universal Personal Telecommunication (UPT)",
            value: "+878",
        },
        { text: "+598 Uruguay", value: "+598" },
        { text: "+998 Uzbekistan", value: "+998" },
        { text: "+678 Vanuatu", value: "+678" },
        { text: "+379 Vatican", value: "+379" },
        { text: "+39 Vatican", value: "+39" },
        { text: "+58 Venezuela (Bolivarian Republic of)", value: "+58" },
        { text: "+84 Viet Nam", value: "+84" },
    ]
}