export default {
	firestorePath: "surveys",
	firestoreRefType: "collection",
	moduleName: "surveys",
	statePropName: "data",
	namespaced: true,
	state: {},
	getters: {},
	serverChange: {
		addedHook: function(updateStore, doc, _, { dispatch }) {
			if (doc.questions) {
				dispatch("questions/fetchByIds", doc.questions, { root: true });
			}
			return updateStore(doc);
		},
	},
	mutations: {},
	actions: {
		getSurveyByUrl({ dispatch }, url) {
			return dispatch("fetchAndAdd", {
				clauses: { limit: 1, where: [["url", "==", url]] },
			}).then((snap) => {
				if (!snap.empty) {
					return snap.docs[0].id;
				}
				return false;
			});
		},
	},
};
