<template>
	<v-sheet flat class="mw-number-graphic mx-auto " height="300px" width="300px">
		<div class="l1">
			<span>{{ number }}</span>
		</div>
		<div class="l2 elevation-3">
			<div class="l2b">
				<div v-html="text"></div>
			</div>
		</div>
		<span class="l3">
			{{subtitle}}
		</span>
	</v-sheet>

</template>

<style lang="less">
@import "./../../assets/css/variables.less";
.mw-number-graphic {
	position: relative;
	margin-top: 20px;
	margin-bottom: 60px;
	> div {
		display: inline-block;
		position: absolute;
	}
	.l1 {
		z-index: 1;
		top: -20%;
        left: -4%;
		span {
			color: white;
			font-family: @mw-fun-font;
			font-size: 300px;
		}
	}
	.l2 {
		border-radius: 50%;
		background-color: @primary;
		height: 100%;
		width: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 0;
		.l2b {
			margin-bottom: 30px;
			text-transform: uppercase;
			color: white;
			font-size: 1.2em;
            font-weight: 300;
			margin-left: 40%;
            padding-top: 20%;
            width: 50%;
		}
	}
	.l3{ 
		background-color: white;
		z-index: 1;
		position: absolute;
		bottom: -80px;
		width: 80%;
		left: 10%;
		text-align: center;
		font-size: 0.9em;
		text-transform: uppercase;
	}
}
</style>

<script>
export default {
	name: "NumberThing",
    props: {
        number: {type: [Number, String ]},
        text: {type: [String, Number] },
        subtitle: {type: [String] },
    }
	// data: () => {
	// 	return {
	// 		number: 3,
	// 		content:
	// 			"Vestibulum quis consectetur orci. Morbi dictum mauris ac leo.",
	// 	};
	// },
};
</script>
