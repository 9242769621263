<template>
	<v-sheet class="mt-5">
		<h3 class="font-weight-light">
			Your Account
		</h3>

		<p>
			You can find options for managing your account below. If you have any
			queries not covered below, please get in touch with our team, who will
			be happy to help!
		</p>
		
		<v-row>
			<v-col>
				<article-card
					v-for="(article, i) in articles"
					:key="i"
					v-bind="article"
				></article-card>
			</v-col>
		</v-row>

	</v-sheet>
</template>
<script>
import ArticleCard from "./ArticleCard.vue";
export default {
	name: "AccountOverview",

	components: {
		ArticleCard,
	},
	data: () => {
		return {
			articles: [
				{
					title: "Technology Check",
					link: "/tech-test",
					subtitle:
						"Test your device and connection to make sure you can attend one of our online appointments<br/><br/><br/>",
					image: "https://firebasestorage.googleapis.com/v0/b/mwx2-753d3.appspot.com/o/uploads%2Fpexels-nataliya-vaitkevich-8927688.jpg?alt=media&token=d7114719-8e57-4126-9308-996e1d4f7a33",
				},
				{
                    title: "Your Details",
					link: "/profile",
					subtitle:
						"View and edit your details to ensure we have your current information<br/><br/><br/>",
					image: "https://firebasestorage.googleapis.com/v0/b/mwx2-753d3.appspot.com/o/uploads%2Fpexels-picjumbocom-225232.jpg?alt=media&token=786c5147-9927-473c-a81c-54134e4e9b48",
				},
				{
					title: "Reward Preferences",
					link: "/rewards",
					subtitle:
						"View and edit available options for receiving your reward for taking part in one of our studies or update your details to make sure your reward reaches you as quickly as possible",
					image: "https://firebasestorage.googleapis.com/v0/b/mwx2-753d3.appspot.com/o/uploads%2Fpexels-suzy-hazelwood-1791583.jpg?alt=media&token=15279498-75a9-4e68-af41-f02b0906e236",
				},
			],
		};
	},
};
</script>
