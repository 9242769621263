<template>
	<v-sheet >
		<render-content class="font-weight-light text-lg text-center" id="currentlyrecruiting"></render-content>
		<v-row>
			<v-col
				cols="12"
				sm="6"
				md="4"
				v-for="(tile, id, i) in tiles"
				:key="id"
			>
				<currently-recruiting-tile
					v-bind="tile"
					:i="i"
				></currently-recruiting-tile>
			</v-col>
		</v-row>
	</v-sheet>
</template>

<script>
import CurrentlyRecruitingTile from "@c/dashboard/CurrentlyRecruitingTile.vue";
import RenderContent from "@c/ui/RenderContent.vue";
export default {
	name: "CurrentlyRecruiting",
	components: { CurrentlyRecruitingTile, RenderContent },
	computed: {
		tiles() {
			return this.$store.state.config.data.currently_recruiting;
		},
	},
};
</script>
