export default {
	firestorePath: "consents",
	firestoreRefType: "collection",
	moduleName: "consents",
	statePropName: "data",
	namespaced: true,
	state: {},
	getters: {},
	sync: {
		defaultValues: {
			status: "published"
		}
	},
	serverChange: {
	},
	mutations: {},
	actions: {
	},
};
