<template>
	<div>
		<dashboard v-if="loggedIn"></dashboard>
		<template v-else>
			<v-parallax
				height="400"
				contain
				src="https://images.unsplash.com/photo-1552049865-2ab6c008e179?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2550&q=80"
			>
				<h2
					class="text-right white--text mb-5 text-h4 text-sm-h3 text-lg-h2 font-weight-lightest"
				>
					Market research with Meisterworks doesn’t just pay great rewards!
				</h2>
				<h3 class="text-right white--text font-weight-light  text-h5 text-sm-h5 text-lg-h4 ">
					Have your say and make future products and services better
				</h3>
				<p class="text-right mt-5">
					<v-btn
						small
						color="primary"
						class="mr-2"
						@click="scrollTo('register')"
					>
						<v-icon class="mr-1">mdi-account</v-icon>
						Register
					</v-btn>
					<v-btn small color="primary" @click="$root.$emit('openLogin')">
						<v-icon class="mr-1">mdi-login-variant</v-icon>
						Log In
					</v-btn>
				</p>
			</v-parallax>

			<v-row class="mt-12 mb-12">
				<v-col cols="12" md="4" class="pr-md-10">
					<icon-graphic
						icon="mdi-currency-gbp"
						title="Real rewards every time!"
						subtitle="No coins or tokens to collect!"
					></icon-graphic>
				</v-col>
				<v-col cols="12" md="4" class="pr-md-5 pl-md-5">
					<icon-graphic
						icon="mdi-chat-processing-outline"
						title="No boring online surveys!"
						subtitle="Speak to a researcher directly!"
					></icon-graphic>
				</v-col>
				<v-col cols="12" md="4" class="pl-md-10">
					<icon-graphic
						icon="mdi-bullhorn-variant"
						title="Be the first to know!"
						subtitle="Try the latest ideas and protypes!"
					></icon-graphic>
				</v-col>
			</v-row>
			<div>
				<fancy-title text="How it Works"></fancy-title>
					<span class=" font-weight-light text-lg text-center" id="how-it-works">
						<render-content id="participantaboutus" ></render-content>
					</span>
				<v-row class="mt-12 mb-12">
					<v-col cols="12" md="4">
						<number-graphic
							number="1"
							text="Check out our current surveys."
							subtitle="Or register to get notified of new surveys"
						></number-graphic>
					</v-col>
					<v-col cols="12" md="4">
						<number-graphic
							number="2"
							text="Let us know if you're interested."
							subtitle="We’ll ask some questions so that surveys are relevant to you"
						></number-graphic>
					</v-col>
					<v-col cols="12" md="4">
						<number-graphic
							number="3"
							text="Look out for our email."
							subtitle="We’ll get in touch once we’re able to confirm your participation "
						></number-graphic>
					</v-col>
				</v-row>
				<fancy-title text="Current Surveys"></fancy-title>
				<currently-recruiting id="current-surveys"></currently-recruiting>

					<fancy-title text="Signing up is easy"></fancy-title>
				<v-row class="mb-12" id="register">
					<v-col cols="3" class="d-none d-md-block">
						<v-img
							style="max-width: 270px;"
							class="mx-auto "
							src="https://firebasestorage.googleapis.com/v0/b/mwx2-753d3.appspot.com/o/registergraphic.png?alt=media&token=bcb43b3d-ce80-4062-9965-06f23b5bc9cc"
						></v-img>
					</v-col>
					<v-col class="pt-4">
						<span class="font-weight-light text-lg">
							<render-content
								id="participantregistering"
							></render-content>
						</span>
						<div class="text-right">
							<v-btn
								large
								router-link
								to="/survey/register"
								color="primary"
							>
								<v-icon class="mr-2">mdi-arrow-right-circle</v-icon>
								Sign Up
							</v-btn>
						</div>
					</v-col>
				</v-row>
			</div>
		</template>
	</div>
</template>
<style lang="less">
.text-lg {
	font-size: 1.2em;
	line-height: 1.4em;
}
.text-xl {
	font-size: 1.5em;
	line-height: 1.5em;
}
.font-weight-lightest {
	font-weight: 200 !important;
}
</style>
<script>
import NumberGraphic from "@c/ui/NumberGraphic.vue";
import IconGraphic from "@c/ui/IconGraphic.vue";
import Dashboard from "./Dashboard.vue";
import RenderContent from "@c/ui/RenderContent.vue";
import CurrentlyRecruiting from "./CurrentlyRecruiting.vue";
import FancyTitle from "@c/ui/FancyTitle.vue";

export default {
	name: "Home",
	components: {
		NumberGraphic,
		Dashboard,
		RenderContent,
		CurrentlyRecruiting,
		FancyTitle,
		IconGraphic,
	},
	computed: {
		loggedIn() {
			return this.$store.state.auth.logged_in;
		},
	},
	methods: {
		scrollTo(id) {
			let el = document.getElementById(id);
			let top = el.offsetTop;

			window.scrollBy({
				top,
				left: 0,
				behavior: "smooth",
			});
		},
	},
	created() {
		// window.location = "https://mwresearch.co.uk"
	},
};
</script>
