import Handlebars from "handlebars";
export default {
	processAnswer(answer, answerOptions) {
		if (typeof answer == "object") {
			let answers = answer.map((a) => {
				return answerOptions[a].text;
			});
			answer = answers.join(", ");
		} else {
			let answerOption = answerOptions[answer];
			if (answerOption) {
				answer = answerOption.text;
			}
		}
		return answer;
	},
	isVisible( obj, data){
		let ruleString =  obj.visible_if;
		if( !ruleString ){
			return true;
		}
		console.log(ruleString)
		
		let test = Handlebars.compile(ruleString);
		let result = test(data);
		try {
			return eval(result)
		}
		catch {
			return false;
		}
	}, 
};
