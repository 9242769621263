<template>
	<span>
		<nav-item
			:expanded="expanded
			
			"
			@click="dialog = true"
			icon="mdi-logout-variant"
			:text="$t('auth.logout')"
		></nav-item>
		<mw-dialog
			v-model="dialog"
			small
			submitBtn
			cancelBtn
			@submit="logout"
			:title="$t('misc.are_you_sure')"
		>
			<template v-slot:dialog-body>
				Are you sure you want to log out?
			</template>
		</mw-dialog>
	</span>
</template>

<script type="text/javascript">
import MwDialog from "@c/ui/MwDialog";
import NavItem from "@c/navigation/Item.vue";
export default {
	name: "Logout",
	props: {
		expanded: { type: Boolean, default: true }, // is this currently expanded
	},
	data: () => {
		return {
			dialog: false,
		};
	},
	components: {
		NavItem,
		MwDialog,
	},
	computed: {
		redirect(){
			if(  this.$route.path == "/" ){
				return false;
			}
			if(  this.$route.meta.do_not_redirect_on_logout ){
				return false;
			}
			return true;
		}
	},
	methods: {
		logout() {
			const self = this;
			self.$store.dispatch("auth/stop").then(() => {
				if (self.redirect ) {
					self.$router.replace("/");
				}
			});
		},
	},
	created() {
		const self = this;
		self.$root.$on("openLogout", (v) => {
			self.prefill = v;
			self.dialog = true;
		});
	},
};
</script>
"
