import { arrayUnion } from "vuex-easy-firestore";
import { arrayRemove } from "vuex-easy-firestore";
import Vue from "vue";

export default {
	firestorePath: "videoRooms/{id}",
	firestoreRefType: "doc",
	moduleName: "videoRoom",
	statePropName: "data",
	namespaced: true,
	state: {
		data: {
			project: null,
			clients: [],
			members: [],
			identities: {},
			waiting_room: [],
			participants: [],
			start: {},
			status: "started",
			moderator: {},
		},
		remote_participants: [],
		video_tracks: [],
		audio_tracks: [],
		main_track: null,
		local_video_track: null,
		local_screen_track: null,
	},
	mutations: {
		MAKE_MAIN(state, content) {
			Vue.set(state, "main_track", content);
		},
		STOP_MAIN(state) {
			Vue.set(state, "main_track", null);
		},
		REMOTE_PARTICIPANT_ENTERS(state, identity) {
			let remote_participants = state.remote_participants;
			remote_participants.push(identity);
			Vue.set(state, "remote_participants", remote_participants);
		},
		REMOTE_PARTICIPANT_LEAVES(state, identity) {
			let remote_participants = state.remote_participants;
			remote_participants = remote_participants.filter(
				(a) => a !== identity
			);
			Vue.set(state, "video_tracks", state.video_tracks.filter( a => a.participant !== identity ));
			Vue.set(state, "audio_tracks", state.audio_tracks.filter( a => a.participant !== identity ));
			Vue.set(state, "remote_participants", remote_participants);
		},
		START_TRACK(state, track) {
			let arr = state[`${track.kind}_tracks`];
			arr.push(track);
			Vue.set(state, `${track.kind}_tracks`, arr);
		},
		STOP_TRACK(state, track) {
			let arr = state[`${track.kind}_tracks`];
			arr = arr.filter((a) => a.sid !== track.trackSid);
			Vue.set(state, `${track.kind}_tracks`, arr);
		},
		START_LOCAL_VIDEO(state, track) {
			Vue.set(state, "local_video_track", track);
		},
		STOP_LOCAL_AUDIO(state, track) {
			Vue.set(state, "local_audio_track", track);
		},
		STOP_LOCAL_VIDEO(state) {
			Vue.set(state, "local_video_track", null);
		},
		START_SCREENSHARE(state, track) {
			Vue.set(state, "local_screen_track", track);
		},
		STOP_SCREENSHARE(state) {
			Vue.set(state, "local_screen_track", null);
		},
	},
	getters: {
		participantAudio(state) {
			return (id) => {
				return state.audio_tracks.filter((a) => a.participant == id);
			};
		},
		participantVideo(state) {
			return (id) => {
				return state.video_tracks.filter((a) => a.participant == id);
			};
		},
		localVideos(state) {
			let tracks = [];
			if (state.local_video_track) {
				tracks.push(state.local_video_track);
			}
			if (state.local_screen_track) {
				tracks.push(state.local_screen_track);
			}
			return tracks;
		},
		canAccess(state, _, rootState) {
			const user = rootState.auth.data;
			if (!state.data) {
				return false;
			}
			if (state.data.members && state.data.members.includes(user.id)) {
				return true;
			}
			let granted = false;

			switch (user.role) {
				case "admin":
					granted = true;
					break;
				case "participant":
					granted =
						state.data.participants &&
						state.data.participants.includes(user.id);
					break;
				case "project_manager":
					granted =
						state.data.project_managers &&
						state.data.project_managers.includes(user.id);
					break;
				case "client":
					granted =
						state.data.clients && state.data.clients.includes(user.id);
					break;
				default:
					return false;
			}
			return granted;
		},
		isMember(state) {
			return (id) => {
				let members = state.data.members;
				if (!members) {
					return false;
				}
				return members.includes(id);
			};
		},
	},
	actions: {
		unsetMain({ commit, state }, track) {
			if (state.main_track && state.main_track.track) {
				if (state.main_track.track.sid == track.trackSid) {
					commit("STOP_MAIN");
				}
			}
		},
		stopLocalVideo({ commit, dispatch, state }) {
			if (state.local_video_track) {
				dispatch("unsetMain", state.local_video_track );
				state.local_video_track.stop();
			}
			commit("STOP_LOCAL_VIDEO");
		},
		stopScreenshare({ commit, dispatch, state }) {
			if (state.local_screen_track) {
				state.local_screen_track.stop();
				dispatch("unsetMain", state.local_screen_track );
			}
			commit("STOP_SCREENSHARE");
		},
		stopLocalAudio({ commit, state }) {
			if (state.local_audio_track) {
				state.local_audio_track.stop();
			}
			commit("STOP_LOCAL_AUDIO");
		},
		checkAccess({ getters, dispatch }) {
			if (getters.canAccess) {
				dispatch("enterConference");
			} else {
				dispatch("enterWaitingRoom");
			}
		},
		fetchRoom({ dispatch }, id) {
			return dispatch("openDBChannel", { pathVariables: { id } }).then(
				() => {
					dispatch("videoRoom/checkAccess", null, { root: true });
				}
			);
		},
		start({ dispatch }, roomName) {
			dispatch("openDBChannel", { pathVariables: { id: roomName } }).then(
				() => {
					dispatch("enterWaitingRoom");
				}
			);
		},
		enterWaitingRoom({ dispatch, rootState }) {
			let name = rootState.auth.data.first_name;
			if( !name ) {
				name = rootState.auth.data.name ? rootState.auth.data.name.split(" ")[0] : "Participant"
			}
			dispatch("patch", {
				waiting_room: arrayUnion(rootState.auth.data.id),
				identities: {
					[rootState.auth.data
						.id]: `${name}`,
				},
			});
		},
		enterConference({ dispatch, rootState }) {
			dispatch("patch", {
				members: arrayUnion(rootState.auth.data.id),
				waiting_room: arrayRemove(rootState.auth.data.id),
			});
		},
		leaveConference({ dispatch, rootState, state }) {
			dispatch("patch", { members: arrayRemove(rootState.auth.data.id) });
			if (state.data.moderator == rootState.auth.data.id) {
				dispatch("patch", { moderator: null });
			}
		},
	},
};
