<template>
	<v-card class="mb-3" :color="color" dark>
		<v-avatar
			size="100"
			class="mt-2 reward-badge"
			color="primary"
			v-if="incentive"
		>
			<span class="num">{{ incentive }}</span>
			<span class="sub">Reward</span>
		</v-avatar>
		<v-img :src="image" height="200px"></v-img>
		<v-card-title>{{ title }} </v-card-title>
		<v-card-subtitle>
			{{ blurb }}
		</v-card-subtitle>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn text :href="`survey/${screener}`">Register</v-btn>
		</v-card-actions>
	</v-card>
</template>
<style lang="less">
.reward-badge {
	right: 5px; 
	position: absolute; 
	z-index: 1;
	.num {
		font-size: 1.8em;
		font-weight: bold;
		position: relative;
		top: -10px
	}
	.sub {
		position: absolute;
		bottom: 15px;
		font-size: 10px;
	}
}
</style>
<script>
export default {
	name: "CurrentlyRecruiting",
	props: {
		title: { type: String },
		i: { type: Number },
		screener: { type: String },
		incentive: { type: [Number, String] },
		blurb: { type: String },
		image: { type: String },
	},
	computed: {
		color() {
			return this.$store.state.colors[this.i];
		},
	},
};
</script>
