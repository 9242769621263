<template>
	<v-list-item class="px-2 menu-item" :dark="dark">
		<v-list-item-icon>
			<v-btn icon @click="dialog = true">
				<v-icon>mdi-account-circle</v-icon>
			</v-btn>
		</v-list-item-icon>
		<v-list-item-title @click="dialog = true">
			{{ $t("auth.login") }}
		</v-list-item-title>
		<v-dialog :eager="true" v-model="dialog" width="360px" id="login-window">
			<div id="mw-firebaseui-auth-container"></div>
		</v-dialog>
	</v-list-item>
</template>

<style scoped></style>
<style lang="less">
@import "../../assets/css/variables.less";
@import "../../../node_modules/firebaseui/dist/firebaseui.css";
.v-dialog--active {
	background-color: white;
}
#mw-firebaseui-auth-container {
	background-color: white;
}
.firebaseui-email-sent {
	display: none;
}
.firebaseui-id-submit,
.firebaseui-button,
.mdl-button--raised.mdl-button--colored {
	background: @primary !important;
	color: white !important;
}
</style>

<script type="text/javascript">
import firebase from "firebase";
import * as firebaseui from "firebaseui";
export default {
	name: "Login",
	props: {
		dark: {},
	},
	data: () => {
		return {
			dialog: false,
			prefill: {},
		};
	},
	computed: {
		path() {
			return window.location.href;
		},
		uiConfig() {
			const self = this;
			return {
				callbacks: {
					signInSuccessWithAuthResult: function(authResult) {
						authResult.user;
						self.dialog = false;
						self.$store.dispatch("auth/addUserDetails");
						self.$router.push({name: "Dashboard"})
					},
				},
				signInOptions: [
					{
						provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
						signInMethod:
							firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
						emailLinkSignIn: function() {
							return {
								url: self.path,
							};
						},
					},
					{
						provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
						whitelistedCountries: ["GB"],
					},
				],
			};
		},
	},
	methods: {
		init() {
			var ui =
				firebaseui.auth.AuthUI.getInstance() ||
				new firebaseui.auth.AuthUI(firebase.auth());
			ui.start("#mw-firebaseui-auth-container", this.uiConfig);
		},
	},
	mounted(){ this.init() },
	created() {
		const self = this;
		self.$root.$on("openLogin", (v) => {
			self.prefill = v;
			self.dialog = true;
		});
	},
};
</script>
