<template>
	<v-sheet>
		<v-row>
			<v-col>
				<render-content
					id="participantdashboard"
					class="mb-12"
				></render-content>
				<h3 class="font-weight-light mb-2">Upcoming Appointments</h3>

				<p class="mb-8">
					You can find information on your upcoming appointments for taking
					part in one of our research studies below. If you need to
					reschedule or cancel an appointment, please use the links
					provided on each of the appointment cards below.
				</p>
				<appointment-tile
					v-for="(appointment, i) in upcomingAppointments"
					:id="appointment.id"
					:user="appointment.user"
					:consented="appointment.consented"
					:appointment_status="appointment.appointment_status"
					:participant_reports_complete="appointment.participant_reports_complete"
					:appointment="appointment.appointment"
					:appointment_start="appointment.appointment_start"
					:appointment_project="appointment.appointment_project"
					:appointment_quota="appointment.appointment_quota"
					:appointment_methodology="appointment.appointment_methodology"
					:appointment_participation_link="appointment.appointment_participation_link"
					:appointment_video_medium="appointment.appointment_video_medium"
					:key="i"
					@click="focus = appointment.appointment"
				></appointment-tile>
				<click-appointment
					:value="focus"
					@close="focus = null"
				></click-appointment>
			</v-col>
		</v-row>

		<my-account></my-account>
	</v-sheet>
</template>

<script>
import MyAccount from "@c/dashboard/MyAccount.vue";
import AppointmentTile from "@c/dashboard/AppointmentTile.vue";
import RenderContent from "@c/ui/RenderContent";
import ClickAppointment from "@c/dashboard/ClickAppointment";
export default {
	name: "Dashboard",
	data: () => {
		return {
			focus: null,
		};
	},
	computed: {
		user() {
			return this.$store.state.auth.data.id;
		},
		upcomingAppointments() {
			const self = this;
			let rows = this.$store.getters.upcomingAppointments;
			return self.mwutils.sortByDate(rows, "appointment_start");
		},
	},
	components: {
		AppointmentTile,
		ClickAppointment,
		RenderContent,
		MyAccount,
	},
	watch: {
		user: {
			immediate: true,
			handler(v) {
				if (v) {
					this.$store.dispatch("appointmentParticipants/fetchMine");
				}
			},
		},
	},
	created() {
		this.$root.$on("startAppointment", (id) => {
			this.focus = id;
		});
	},
};
</script>
