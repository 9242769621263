<template>
	<v-card max-width="357" class="mr-3 mb-3 inline-tile">
		<v-img :src="image" height="200px"></v-img>

		<v-card-title>
			{{ title }}
		</v-card-title>

		<v-card-subtitle v-html="subtitle"> </v-card-subtitle>

		<v-card-actions>
			<v-btn color="primary" text @click="go">
				Read More
			</v-btn>

			<v-spacer></v-spacer>

			<v-btn icon @click="go">
				<v-icon>mdi-chevron-right</v-icon>
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	name: "ArticleCard",
	props: {
		title: { type: String },
		image: { type: String },
		subtitle: { type: String },
    link: {type: [String, Object] }
	},
	methods: {
		go() {
			if (!this.$store.state.auth.data.id) {
				this.$root.$emit("openLogin");
			}
      else{
        if( this.link ){
          this.$router.push(this.link)
        }
      }
		},
	},
};
</script>
