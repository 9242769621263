<template>
	<v-app-bar app color="white" flat>
		<v-btn icon v-if="isMobile" @click="$root.$emit('openDrawer')"
			><v-icon>mdi-menu</v-icon></v-btn
		>
		<v-spacer></v-spacer>
		<v-toolbar-title class="font-weight-light d-md-block pa-2" :right="isMobile"  >
			<a href="/">

				<img src="../../assets/images/logo.png" style="max-height: 42px; margin-top: 8px" />
			</a>
		</v-toolbar-title>
	</v-app-bar>
</template>

<style lang="less" scoped>
img {
	cursor: pointer;
}
</style>
<script>
export default {
	name: "TopNav",
	methods: {
		goHome() {
			if (this.$route.path !== "/") {
				this.$router.push("/");
			}
		},
	},
	inject: ["isMobile"],
	computed: {
		height() {
			return this.isMobile ? 50 : 80;
		},
		siteTitle() {
			return this.$store.state.site_name;
		},
	},
};
</script>
