<template>
	<v-card color="primary" height="100%" dark>
		<v-card-title style="word-break: break-word">
			Tell Us You're Attending
		</v-card-title>
		<v-card-text>
			<p>
				Confirm this date and time
			</p>
			<v-btn block small outlined class="mt-1" color="primary" style="background: white" @click="attend"
				>
				<v-icon class="mr-2" small>mdi-check-circle</v-icon>I'm attending!
				</v-btn
			>
			<v-btn block small text @click="cancel" color="white">
				I can't make it!
			</v-btn>
		</v-card-text>
		<mw-dialog
			v-model="dialog"
			:title="attending ? 'Confirm Appointment' : 'Cancel Appointment'"
			cancel-btn
			small
			submit-btn
			@submit="submit"
		>
			<template v-slot:dialog-body>
				<p v-if="attending">
					This will let us know that you are attending this appointment.
				</p>
				<div v-else>
					<p class="mt-2">
					Thanks for letting us know that you won't be attending this appointment. Please use the comments box to give any more details. 
					</p>
					<v-checkbox v-model="cancelType" value="rearrange" hide-details label="I'd like to rearrange"></v-checkbox>
					<v-checkbox v-model="cancelType" value="cancel" hide-details label="I'd like to cancel this appointment"></v-checkbox>
				</div>
				<v-textarea v-model="participant_comments" label="Comments"></v-textarea>
			</template>
		</mw-dialog>
	</v-card>
</template>

<script>
import MwDialog from "@c/ui/MwDialog";
export default {
	name: "MarkConfirmed",
	props: {
		id: { type: String },
	},
	components: {
		MwDialog,
	},
	data: () => {
		return {
			participant_comments: null,
			attending: true,
			dialog: false,
			cancelType: "rearrange", 
		};
	},
	computed: {
		appointment() {
			return this.$store.getters.singleAppointment(this.id);
		},
		auth() {
			return this.$store.state.auth.data;
		},
	},
	methods: {
		attend(){
			this.attending = true;
			this.dialog = true
		},
		cancel() {
			this.attending = false;
			this.dialog = true;
		},
		submit() {
			let appointment_status;
			if( this.attending ){
				appointment_status = "confirmed";
			}
			else{
				if( this.cancelType == "cancel" ){
					appointment_status = "cancelled";
				}
				if( this.cancelType == "rearrange" ){
					appointment_status = "hold";
				}
			}
			this.$store.dispatch("appointmentParticipants/patch", {
				id: this.id,
				participant_comments: this.participant_comments,
				appointment_status,
				participant_set_status_at: new Date()
			});
		},
	},
};
</script>
