<template>
	<v-card color="greyblue" height="100%" tile dark>
		<v-card-text>
			<span class="text-lg-h4 text-xl font-weight-light">
				Provide your reward preferences
			</span>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn text small router-link to="/rewards">
				Go
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	name: "GoToIncentive",
	data: () => {
		return {
			image:
				"https://firebasestorage.googleapis.com/v0/b/mwx2-753d3.appspot.com/o/uploads%2Fchill.jpg?alt=media&token=5c36d375-fd5f-435b-8960-6de2e2c85502",
		};
	},
};
</script>
