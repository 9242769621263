<template>
	<v-card color="primary" dark tile height="100%">
		<v-card-text>
				You need to review the Privacy and Consent Statement before you can
				take part

		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn icon large router-link target="_blank" :to="`/consent/${id}`">
				<v-icon large>mdi-arrow-right-circle</v-icon>
			</v-btn>
		</v-card-actions>
		<v-card-text>
			<mw-dialog
				v-model="dialog"
				cancel-btn
				title="Why am I still being asked to submit this?"
			>
				<template v-slot:button>
					<a class="small-text white--text" @click="dialog = true">
						<v-icon small>mdi-help-circle</v-icon>
						I've already done this!
					</a>
				</template>
				<template v-slot:dialog-body>
					If you've already submitted this information, please don't worry.
					This message will disappear once a member of the team has
					reviewed your submission.
				</template>
			</mw-dialog>
		</v-card-text>
	</v-card>
</template>

<script>
import MwDialog from "@c/ui/MwDialog";

export default {
	name: "ConsentWarning",
	props: {
		id: { type: String },
	},
	data: () => {
		return {
			dialog: null,
		};
	},
	components: {
		MwDialog,
	},
};
</script>
