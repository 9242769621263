export default {
	firestorePath: "questions",
	firestoreRefType: "collection",
	moduleName: "questions",
	statePropName: "data",
	namespaced: true,
	serverChange: {
		addedHook: function(updateStore, doc, _, { dispatch }) {
			if (doc.profile_field) {
				dispatch("profileFieldValues/fetchUserValues", doc.profile_field, { root: true });
			}
			return updateStore(doc);
		},
	},
	state: {},
	getters: {
		title(state) {
			return (id) => {
				let question = state.data[id];
				if (!question || !id) {
					return "";
				}
				if (question.type == "html") {
					return question.content;
				}
				return question.title;
			};
		},
		subquestions(state){
			return (id) => {
				let question = state.data[id];
				return Object.values(question.subquestions);
			}
		},
		hasConditions(state){
			return (id) => {
				let question = state.data[id];
				if (!question) {
					return false;
				}
				if (question.visible_if && question.visible_if.length) {
					return true;
				}

				if( Object.values(question.answer_options).map( option => option.visible_if ).filter( option => option ).length ){
					return true;
				}
				return false;

			}
		},
		isLabelled(state) {
			return (id) => {
				let question = state.data[id];
				if (!id || !question) {
					return null;
				}
				let questionType = question.type;
				let labelledTypes = ["checkboxes", "tiles"];
				return labelledTypes.includes(questionType);
			};
		},
		isHierarchical(state) {
			return (id) => {
				let question = state.data[id];
				if (!id || !question) {
					return null;
				}
				let questionType = question.type;
				let hierarchicalTypes = ["tiles"];
				return hierarchicalTypes.includes(questionType);
			};
		},
		isMultiple(state) {
			return (id) => {
				let question = state.data[id];
				if (!id || !question) {
					return null;
				}
				return question.multiple;
			};
		},
		labelledAnswer(state) {
			return (id, value) => {
				let question = state.data[id];
				let answer = question.answer_options[value];
				if (!answer) {
					return value;
				}
				return answer.text || value;
			};
		},
		textAnswer(_, getters) {
			return (id, value) => {
				if (getters.isLabelled(id)) {
					return getters.labelledAnswer(id, value);
				}
				return value;
			};
		},
		hierarchicalAnswer(state, getters) {
			return (id, value) => {
				let question = state.data[id];
				return Object.keys(value).map((q) => {
					let v = value[q];
					let subquestion = question.subquestions[q];
					let answer = "";
					if (subquestion) {
						answer = `${subquestion.text}: `;
					}
					answer += getters.textAnswer(id, v);
					answer += "\n";
					return answer;
				});
			};
		},
		parseValueToString(_, getters) {
			return (id, value) => {
				if (!value) {
					return "";
				}
				if (Array.isArray(value)) {
					// if (getters.isHierarchical(id)) {
					// 	return getters.hierarchicalAnswer(id, value);
					// } else {
					try {
						return value.map((v) => getters.textAnswer(id, v)).join(", ");
					} catch {
						return "";
					}
					// }
				} else {
					return getters.textAnswer(id, value);
				}
			};
		},
	},
	mutations: {},
	actions: {},
};
