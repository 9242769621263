export default {
	firestorePath: "appointments",
	firestoreRefType: "collection",
	moduleName: "appointments",
	statePropName: "data",
	namespaced: true,
	state: {},
	getters: {
		myActiveAppointments(state){
			let app = Object.values(state.data);
			return app.filter( a => a.status == "published" );
		}
	},
	serverChange: {
		addedHook: function(updateStore, doc, _, { dispatch }) {
			if (doc.project) {
				dispatch("projects/fetchById", doc.project, { root: true });
			}
			return updateStore(doc);
		},
	},
	mutations: {},
	actions: {
	},
};
