<template>
    <div>
        <v-alert class="mt-2 mb-2" dense text color="primary" v-if="medium == 'ms teams'">
            <a href="video-help/ms-teams"> Need help with Microsoft Teams?<br /> </a>
        </v-alert>
        <v-alert class="mt-2 mb-2" dense text color="primary" v-if="medium == 'skype'">
            <a href="video-help/skype"> Need help with Skype?<br /> </a>
        </v-alert>
        <v-alert class="mt-2 mb-2" dense text color="primary" v-if="medium == 'zoom'">
            <a href="video-help/zoom"> Need help with Zoom?<br /> </a>
        </v-alert>
        <v-alert class="mt-2 mb-2" dense text color="primary" v-if="medium == 'google meet'">
            <a href="video-help/meet"> Need help with Google Meet?<br /> </a>
        </v-alert>
        <v-alert class="mt-2 mb-2" dense text color="primary" v-if="medium == 'internal'">
            <a href="video-help/video"> Need help?<br /> </a>
        </v-alert>
    </div>
</template>


<script>

    export default {
        name: "ConsentWarning", 
        props: {
            medium: {type: String }
        }
    }
</script>