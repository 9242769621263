<template>
	<v-app>
		
		<top-nav></top-nav>
		<sidebar></sidebar>
		<v-main>
			<v-container flat class="pa-8" style="margin-bottom: 320px">
				<router-view />
			</v-container>
				<site-footer ></site-footer>
		</v-main>
	</v-app>
</template>

<style lang="less">
@import url("./assets/css/main.less");
</style>

<script>
import Vuex from "vuex";
import TopNav from "@c/navigation/Top.vue";
import Sidebar from "@c/navigation/Side.vue";
import SiteFooter from "@c/navigation/SiteFooter.vue";
export default {
	name: "App",
	data: () => ({}),
	computed: {
		...Vuex.mapState({
			state: (state) => state,
		}),
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown;
		},
	},
	components: {
		TopNav,
		SiteFooter,
		Sidebar,
	},
	provide() {
		return {
			isMobile: this.isMobile,
		};
	},
	created() {
		this.$store.dispatch("config/openDBChannel");
		document.title = this.$store.state.site_name;
	},
};
</script>
