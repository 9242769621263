<template>
	<div>
		<p class="subtitle-1">{{ projectName }}</p>
	</div>
</template>

<script>
export default {
	name: "ProjectTitle",
	props: {
		id: { type: String },
	},
	computed: {
		project() {
			return this.$store.state.projects.data[this.id];
		},
		projectName() {
			if (!this.project) {
				return "";
			}
			return this.project.public_name || "Project";
		},
	},
};
</script>
