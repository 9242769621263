import utilities from "../assets/utilities";
import timezones from "./timezones";

export default {
	timezones() {
		return timezones;
	},
	getByKey(state) {
		return (storeModule, key, value, returnObject) => {
			let items = state[storeModule].data;
			items = Object.values(items);
			items = items.filter((i) => i[key] == value);
			if (returnObject) {
				items = Object.fromEntries(items.map((v) => [v.id, v]));
			}
			return items;
		};
	},
	getByKeyDoesNotContain(state) {
		return (storeModule, key, value, returnObject) => {
			let items = state[storeModule].data;
			items = Object.values(items);
			items = items.filter((i) => !i[key] || !i[key].includes(value));
			if (returnObject) {
				items = Object.fromEntries(items.map((v) => [v.id, v]));
			}
			return items;
		};
	},
	getByKeyContains(state) {
		return (storeModule, key, value, returnObject) => {
			let items = state[storeModule].data;
			items = Object.values(items);
			items = items.filter((i) => i[key] && i[key].includes(value));
			if (returnObject) {
				items = Object.fromEntries(items.map((v) => [v.id, v]));
			}
			return items;
		};
	},
	getByKeyIn(state) {
		return (storeModule, key, value, returnObject) => {
			let items = state[storeModule].data;
			items = Object.values(items);
			items = items.filter((i) => value.includes(i[key]));
			if (returnObject) {
				items = Object.fromEntries(items.map((v) => [v.id, v]));
			}
			return items;
		};
	},
	singleAppointment(state){
		return (id) => {
			let row = state.appointmentParticipants.data[id];
			let appointment = state.appointments.data[row.appointment];
			if( !appointment ){
				return null;
			}
			if( appointment.status == "deleted" || row.status == "deleted" ) {
				return null;
			}
			['project', 'start', 'quota', 'video_medium', 'methodology', 'participation_link'].forEach( key => {
				key = utilities.strToKey(key);
				row[`appointment_${key}`] = appointment[key];
			})
			return row;
		}
	},
	singleAppointments(state, getters){
		let rows = Object.values(state.appointmentParticipants.data);
		return rows.map( row => {
			return getters.singleAppointment(row.id);
		});
	},
	upcomingAppointments(_, getters){
		let today = new Date();
		today.setHours(7);
		let rows = getters.singleAppointments;
		rows = rows.filter( row => row );
		rows = rows.filter( row => !row.appointment_start || row.appointment_start.toDate() > today );
		rows = rows.filter( row => row.appointment_status !== "cancelled");
		return rows;
	}

};
