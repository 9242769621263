import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import firebase from "firebase";
Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/about",
		name: "About",
		component: () =>
			import( "../views/About.vue"),
	},
	{
		path: "/research-policies-and-notices",
		name: "ResearchPoliciesAndNotices",
		component: () =>
			import( "../views/ResearchPoliciesAndNotices.vue"),
	},
	{
		path: "/video/:id",
		name: "Video",
		props: true,
		component: () =>
		import( "../views/Video.vue"),
	},
	{
		path: "/join/:id",
		name: "Join",
		props: true,
		component: () =>
		import( "../views/Join.vue"),
	},
	{
		path: "/consent/:id",
		name: "Consent",
		props: true,
		component: () =>
		import( "../views/Consent.vue"),
	},
	{
		path: "/rewards",
		name: "Rewards",
		props: true,
		component: () =>
		import( "../views/Rewards.vue"),
	},
	{
		path: "/default-consent",
		name: "DefaultConsent",
		props: true,
		component: () =>
		import( "../views/DefaultConsent.vue"),
	},
	{
		path: "/scheduling/:id",
		name: "Scheduler",
		props: true,
		component: () =>
		import( "../views/Scheduler.vue"),
	},
	{
		path: "/profile",
		name: "Profile",
		meta: {
			requires_auth: true,
		},
		component: () =>
		import( "../views/Profile.vue"),
	},
	{
		path: "/dashboard",
		name: "Dashboard",
		meta: {
			requires_auth: true,
		},
		component: () =>
		import( "../views/Dashboard.vue"),
	},
	{
		path: "/tech-test",
		name: "TechTest",
		component: () =>
			import( "../views/TechTest.vue"),
	},
	{
		path: "/survey/:url",
		props: true,
		name: "Survey",
		meta: {
			do_not_redirect_on_logout: true
		},
		component: () =>
		import( "../views/Survey.vue"),
	},
	{
		path: "/thank-you",
		name: "ThankYou",
		props: true,
		component: () =>
			import( "../components/survey/render/ThankYou.vue"),
	},
	{
		path: "/thank-you",
		name: "ThankYou",
		props: true,
		component: () =>
			import( "../components/survey/render/ThankYou.vue"),
	},
	{
		path: "/scheduled",
		name: "SchedulerThanks",
		props: true,
		component: () =>
			import( "../components/scheduler/ThankYou.vue"),
	},
	{
		path: "/sign-in",
		name: "SignIn",
		props: true,
		component: () =>
			import( "../views/SignIn.vue"),
	},
	{
		path: "/contact",
		name: "Contact",
		meta: {
		},
		component: () =>
			import( "../views/Contact.vue"),
	},
	{
		path: "/help/faq",
		name: "Faq",
		component: () =>
			import( "../views/Faq.vue"),
	},
	{
		path: "/video-help/:medium",
		name: "VideoHelp",
		props: true,
		component: () =>
			import( "../views/VideoHelp.vue"),
	},
	{
		path: "/help/support",
		name: "Support",
		
		component: () =>
			import( "../views/Support.vue"),
	},
	{
		path: "/help/website-policies-and-notices",
		name: "Privacy",
		meta: {
			organisation: true
		},
		component: () =>
			import( "../views/Privacy.vue"),
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach(async (to, from, next) => {
	const requiresAuth = to.matched.some((record) => record.meta.requires_auth);
	if (requiresAuth && !(await firebase.getCurrentUser())) {
		next("/");
	} else {
		next();
	}
});

export default router;
