export default {
	firestorePath: "profileFieldValues",
	firestoreRefType: "collection",
	moduleName: "profileFieldValues",
	statePropName: "data",
	namespaced: true,
	state: {},
	getters: {},
	sync: {
		defaultValues: {
			status: "published",
		},
	},
	serverChange: {},
	mutations: {},
	actions: {
		fetchUserValues({ dispatch, rootState }, id) {
			let user = rootState.auth.data.id;
			if( !user ){
				return;
			}
			return dispatch("fetchAndAdd", {
				clauses: {
					where: [
						["profile_field", "==", id],
						["user", "==", user],
					],
					orderBy: ["created_at", "desc"],
					limit: 1,
				},
			});
		},

		saveSurvey({ dispatch, rootState }, { survey, data, user }) {
			if( !user ){
				user = rootState.auth.data.id;
			}
			let questions = rootState.surveys.data[survey].questions;
			questions.forEach((q) => {
				let value = data[q];
				let question = rootState.questions.data[q];
				let profile_field = question.profile_field;
				if (profile_field && value) {
					dispatch("insert", { profile_field, user, value, question: q });
				}
			});
		},
	},
};
