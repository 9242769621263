<template>
	<v-card width="100%" class="mb-2 font-weight-light inline-tile">
		<v-card-text>
			<v-row>
				<v-col cols="12" sm="4" md="3" lg="3">
					<project-title :id="appointment_project"></project-title>
					<div class="mb-2" v-if="appointment_start">
						{{ appointmentTime }}
					</div>
					<project-info :id="appointment_project"></project-info>
					<v-chip small v-if="appointment_status == 'hold'" color="primary" class="mt-2">Appointment is on hold</v-chip>
				</v-col>
				<v-col>
					<!-- <v-row class="mt-2">
						<v-col cols="10">
							{{ instructionText }}
						</v-col>
						<v-col class="text-right">
						</v-col>
					</v-row> -->
					<v-row>
						<v-col cols="12" md="4" sm="6"  v-if="showConsent">
							<consent-warning :id="id"></consent-warning>
						</v-col>
						<v-col cols="12" md="4" sm="6" v-if="enableMarkConfirmed">
							<mark-confirmed
								:id="id"
							></mark-confirmed>
						</v-col>
						<v-col cols="12" sm="4" v-if="!over || appointmentIsComplete">
							<go-to-incentive
								
							></go-to-incentive>
						</v-col>
						<v-col cols="12" sm="4" v-if="enableMarkAttended">
							<mark-attended
								:id="id"
							></mark-attended>
						</v-col>
					</v-row>
					<appointment-tile-footer :id="id">

					</appointment-tile-footer>
				</v-col>
			</v-row>
			<v-row v-if="!over && appointment_methodology == 'video'">
				<v-col>
					<medium-warning :medium="appointment_video_medium"></medium-warning>
				</v-col>
			</v-row>
		</v-card-text>
<!-- <appointment-methodology-icon
	:video_medium="appointment_video_medium"
	:methodology="appointment_methodology"
></appointment-methodology-icon>
 -->
	</v-card>
</template>

<style lang="less">
.inline-tile {
	display: inline-grid;
	float: left;
	margin-right: 14px;
	margin-bottom: 14px;
}
.large-text {
	font-size: 1.4em;
}
</style>

<script>
import ConsentWarning from "@c/dashboard/ConsentWarning";
import MediumWarning from "@c/dashboard/MediumWarning.vue";
import MarkAttended from "@c/dashboard/MarkAttended.vue";
import MarkConfirmed from "@c/dashboard/MarkConfirmed.vue";
import GoToIncentive from "@c/dashboard/GoToIncentive.vue";
import ProjectInfo from "@c/dashboard/ProjectInfo.vue";
import ProjectTitle from "@c/dashboard/ProjectTitle.vue";
// import AppointmentMethodologyIcon from "@c/dashboard/AppointmentMethodologyIcon.vue";
import AppointmentTileFooter from "@c/dashboard/AppointmentTileFooter.vue";

export default {
	name: "AppointmentCard",
	props: {
		id: { type: String },
		user: { type: String },
		consented: { type: Boolean },
		appointment_status: { type: String },
		participant_reports_complete: { type: Boolean },
		appointment: { type: String },
		appointment_start: { type: [Object, null] },
		appointment_project: { type: String },
		appointment_quota: { type: String },
		appointment_methodology: { type: String },
		appointment_participation_link: { type: String },
		appointment_video_medium: { type: String },
	},
	components: {
		MediumWarning,
		ConsentWarning,
		MarkAttended,
		GoToIncentive,
		ProjectInfo,
		MarkConfirmed,
		ProjectTitle,
		// AppointmentMethodologyIcon,
		AppointmentTileFooter,
	},
	computed: {
		project(){
			return this.$store.state.projects.data[this.appointment_project];
		},
		appointmentIsComplete(){
			return this.appointment_status == "complete" || this.participant_reports_complete;
		},
		showConsent(){
			if( !this.project ){
				return false;
			}
			return !this.consented && this.project.consent;
		},
		instructionText() {
			if( ['recruited', 'new', 'hold'].includes(this.appointment_status) ){
				return "Please complete the below ASAP to secure your appointment";
			}

			return "";
		},
		soon() {
			if (!this.appointment_start) {
				return false;
			}
			let now = new Date();
			let start = this.mwtimeutils.parseDateToMoment(this.appointment_start);
			start = start.subtract(10, "minutes");
			return start < now;
		},
		over() {
			if (!this.appointment_start) {
				return false;
			}
			let now = new Date();
			let start = this.mwtimeutils.parseDateToMoment(this.appointment_start);
			start = start.add(15, "minutes");
			return start < now;
		},
		enableMarkConfirmed() {
			if (
				!this.over &&
				["recruited", "new"].includes(this.appointment_status)
			) {
				return true;
			}
			return false;
		},
		enableMarkAttended() {
			if (!this.participant_reports_complete) {
				if (!this.appointment_start) {
					return true;
				}
				if (this.over) {
					return true;
				}
			}
			return false;
		},
		appointmentTime() {
			if (!this.appointment_start) {
				return null;
			}
			return this.mwtimeutils.formatAsDateTimeString(this.appointment_start);
		},
	},
};
</script>
