export default {
	firestorePath: "config/default",
	firestoreRefType: "doc",
	moduleName: "config",
	statePropName: "data",
	namespaced: true,
	state: {
        currently_recruiting: {}
	},
	getters: {},
	mutations: {},
	actions: {},
};
