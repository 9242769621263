<template>
	<v-navigation-drawer
		v-model="drawer"
		app
		:permanent="!isMobile"
		:mini-variant="mini"
		style="z-index: 100"
	>
		<logged-in v-if="loggedIn"></logged-in>
		<login v-else></login>
		<v-divider></v-divider>

		<v-list dense>
			<nav-item
				:expanded="!mini"
				v-for="item in items"
				:key="item.title"
				:link="item.link"
				v-bind="item"
			>
			</nav-item>
			<template v-if="!loggedIn && isHome">

				<nav-item
					:expanded="!mini"
					v-for="item in homeItems"
					:key="item.title"
					@click="scrollTo(item.to)"
					v-bind="item"
				>
				</nav-item>
			</template>
			<template v-if="loggedIn">
				<nav-item
					:expanded="!mini"
					v-for="item in authItems"
					:key="item.title"
					:link="item.link"
					v-bind="item"
				>
				</nav-item>
			</template>
		</v-list>
		<template v-slot:append>
			<v-list dense>
				<nav-item
					:expanded="!mini"
					link="/contact"
					icon="mdi-at"
					text="Contact Us"
				></nav-item>
				<chat :expanded="!mini"></chat>
				<logout :expanded="!mini" v-if="loggedIn"></logout>
				<nav-item
					@click="closeMenu"
					:expanded="!mini"
					:text="$t('buttons.collapse')"
					:icon="mini ? 'mdi-chevron-right' : 'mdi-chevron-left'"
				></nav-item>
			</v-list>
		</template>
	</v-navigation-drawer>
</template>
<script>
import Chat from "@c/ui/Chat.vue";
import LoggedIn from "@c/auth/LoggedIn";
import Login from "@c/auth/Login";
import Logout from "@c/auth/Logout.vue";
import NavItem from "@c/navigation/Item";

export default {
	name: "Sidebar",
	computed: {
		loggedIn() {
			return this.$store.state.auth.logged_in;
		},
		isHome(){
			return this.$route.name == "Home"
		}
	},
	inject: ["isMobile"],
	data() {
		return {
			drawer: true,
			items: [{ text: "Home", icon: "mdi-home", link: "/" }],
			authItems: [
				{ text: "Rewards", icon: "mdi-currency-gbp", link: "/rewards" },
				{ text: "Tech Check", icon: "mdi-laptop", link: "/tech-test" },
			],
			homeItems: [
				{
					text: "How it Works",
					icon: "mdi-cog",
					to: "how-it-works",
				},
				{
					text: "Current Surveys",
					icon: "mdi-star",
					to: "current-surveys",
				},
				{ text: "Register", icon: "mdi-pencil", to: "register" },
			],
			mini: true,
		};
	},
	components: {
		Login,
		Chat,
		LoggedIn,
		Logout,
		NavItem,
	},
	methods: {
		closeMenu() {
			if (this.isMobile) {
				this.drawer = false;
			} else {
				this.mini = !this.mini;
			}
		},
		scrollTo(id) {
			let pos = document.getElementById(id).offsetTop - 20;
			window.scrollTo({
				top: pos,
				behavior: "smooth",
			});
		},
	},
	created() {
		this.$root.$on("openDrawer", () => (this.drawer = true));
		if (this.isMobile) {
			this.mini = false;
			this.drawer = false;
		}
	},
};
</script>
