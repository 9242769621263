<template>
	<v-row v-if="isClickableMethodology">
		<v-col class="text-right">
			<small v-if="!enableClick"
				>Links become available 5 minutes before the start of the
				appointment.</small
			>
			<v-btn
				:disabled="!enableClick"
				small
				class="ml-7"
				color="primary"
				@click="go"
			>
				Get Started
			</v-btn>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "AppointmentTileFooter",
	props: {
		id: { type: String },
	},
	methods: {
		go() {
			if (this.appointment.appointment_video_medium == "internal") {
				this.$router.push("/join/" + this.id);
			} else {
				this.$root.$emit("startAppointment", this.appointment.appointment);
			}
		},
	},
	computed: {
		appointment() {
			return this.$store.getters.singleAppointment(this.id);
		},
		isClickableMethodology() {
			return this.appointment.appointment_methodology !== "f2f";
		},
		isTimed() {
			return this.appointment.appointment_start !== null;
		},
		isSoon() {
			if (!this.isTimed) {
				return false;
			}
			let now = new Date();
			let start = this.mwtimeutils.parseDateToMoment(
				this.appointment.appointment_start
			);
			start = start.subtract(10, "minutes");
			return start < now;
		},
		project() {
			return this.$store.state.projects.data[this.appointment.project];
		},
		enableClick() {
			if (this.isTimed) {
				return this.isSoon;
			} else {
				return this.linksAreLive;
			}
		},
		linksAreLive() {
			if (!this.project) {
				return null;
			}
			return this.project.links_are_live;
		},
		appointmentTime() {
			if (!this.isTimed) {
				return null;
			}
			return this.mwtimeutils.formatAsDateTimeString(
				this.appointment.appointment_start
			);
		},
	},
};
</script>
