export default {
	firestorePath: "appointmentParticipants",
	firestoreRefType: "collection",
	moduleName: "appointmentParticipants",
	statePropName: "data",
	namespaced: true,
	state: {},
	getters: {},
	serverChange: {
		addedHook: function(updateStore, doc, _, { dispatch }) {
			if (doc.appointment) {
				dispatch("appointments/fetchById", doc.appointment, false, { root: true });
			}
			return updateStore(doc);
		},
	},
	mutations: {},
	actions: {
		fetchMine({dispatch, rootState}){
			let user = rootState.auth.data.id;
			if( !user ){
				return;
			}
			dispatch("fetchAndAdd", {
				clauses: {
					where: 	[["user", "==", user], ["status","==", "published"]]
				}
			})
		},
		fetchByUser(dispatch, {user, appointment}){
			dispatch("fetchAndAdd", {
				clauses:{
					where: [
						["user", "==", user ],
						["appointment", "==", appointment ],
						["status", "==", "published"]
					]
				}
			})
		}
	},
};
