export default {
	firestorePath: "emails",
	firestoreRefType: "collection",
	moduleName: "emails",
	statePropName: "data",
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		
		contactForm({ rootState, dispatch }, {email, message} ) {
			let admins = ["8iVJ3IjI49XICh9WpALQY2CBb6x1", "ZruM8LlqOJeSsiYcUZn2HitZHuE2"]
			var mail = {
				toUids: admins,
				template: {
					data: {email, message},
					name: "contactform",
				},
				user: rootState.auth.data.id || null,
			};
			return dispatch("insert", mail);

		}
	},
};
