<template>
	<div v-if="project">
		<small class="text-italic text-justified">{{ project.info_for_participants }}</small>
	</div>
</template>

<script>
export default {
	name: "ProjectInfo",
	props: {
		id: { type: String },
	},
	computed: {
		project() {
			return this.$store.state.projects.data[this.id];
		},
	},
};
</script>
