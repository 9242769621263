export default {
	firestorePath: "surveyResponses",
	firestoreRefType: "collection",
	moduleName: "surveyResponses",
	statePropName: "data",
	namespaced: true,
	state: {},
	getters: {},
	sync: {
		defaultValues: {
			status: "submitted",
			participant: false,
			logged_in: false,
			survey: null,
		},
	},
	mutations: {},
	actions: {
		newResponse(
			{ dispatch, rootState },
			{ survey, data, participant, contact_info }
		) {
			
			let user = rootState.auth.data;
			if( user.id ){
				if (!participant) {
					participant = user ? user.id : null;
				}
			}
			if( participant  ){
				dispatch("profileFieldValues/saveSurvey", {survey, data, user: participant }, {root: true});
			}
			let logged_in = rootState.auth.data ? true : false;
			for (var key in data) {
				if (typeof data[key] == "undefined") {
					data[key] = null;
				}
			}
			let response = {
				participant,
				data,
				contact_info,
				survey,
				logged_in,
			};
			dispatch("insert", response);
		},
	},
};
