export default {
	firestorePath: "appointmentParticipants/{id}",
	firestoreRefType: "doc",
	moduleName: "appointmentParticipant",
	statePropName: "data",
	namespaced: true,
	state: {},
	getters: {},
	serverChange: {
		modifiedHook : function(updateStore, doc, _, { dispatch }) {
			if (doc.appointment) {
				dispatch("appointment/openDBChannel", {id: doc.appointment}, { root: true });
			}
			return updateStore(doc);
		},
	},
	mutations: {},
	actions: {
		fetchByUser(dispatch, {user, appointment}){
			dispatch("openDBChannel", {
				clauses:{
					where: [
						["user", "==", user ],
						["appointment", "==", appointment ],
						["status", "==", "published"]
					]
				}
			})
		}
	},
};
