<template>
	<v-card color="greyblue" dark flat class="rounded-0">
		<div class="mw-icon-graphic pt-9 pb-5 pl-2 pr-2">
			<div class="l">
				<v-avatar color="white" size="113">
					<v-icon color="greyblue" x-large>{{ icon }}</v-icon>
				</v-avatar>
			</div>
			<div class="r pl-8 pr-4">
				<h3 class="text-sm text-md-h5 l2">{{ title }}</h3>
				<p class="text-sm text-md-h5 mt-5">
					{{ subtitle }}
				</p>
			</div>
		</div>
	</v-card>
</template>
<style lang="less">
.mw-icon-graphic {
	position: relative;
	height: auto;
	min-height: 275px;
	.l {
		position: absolute;
		left: -8%;
		width: 40%;
		transform: rotate(-16deg);
	}
	.r {
		position: absolute;
		left: 25%;
		width: 75%
	}
}
.l2 {
	text-transform: uppercase;
	font-weight: lighter;
	font-size: 1.3em;
}
</style>
<script>
export default {
	name: "IconGraphic",
	props: {
		icon: { type: String },
		title: { type: String },
		subtitle: { type: String },
	},
};
</script>
