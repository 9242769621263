<template>
	<v-card
		color="white"
		height="100%"
        tile
        max-width="300px"
	>
		<v-img :src="image" height="120px"></v-img>
		<v-card-text>
			Let us know that you've completed the research!
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<mw-dialog
				v-model="dialog"
				title="Tell Us You've Taken Part"
				cancel-btn
				submit-btn
				@submit="submit"
			>
				<template v-slot:button>
					<v-btn icon small color="primary" @click="dialog = true">
						<v-icon>mdi-arrow-right-circle</v-icon>
					</v-btn>
				</template>
				<template v-slot:dialog-body>
					This will let the us know that you have completed this
					research.
				</template>
			</mw-dialog>
		</v-card-actions>
	</v-card>
</template>

<script>
import MwDialog from "@c/ui/MwDialog";
export default {
	name: "MarkAttended",
	props: {
		id: { type: String },
	},
	components: {
		MwDialog,
	},
	data: () => {
		return {
			dialog: false,
			image:
				"https://firebasestorage.googleapis.com/v0/b/mwx2-753d3.appspot.com/o/uploads%2Fchill.jpg?alt=media&token=5c36d375-fd5f-435b-8960-6de2e2c85502",
		};
	},
	methods: {
		submit() {
			this.$store.dispatch("appointmentParticipants/patch", {
				id: this.id,
				participant_reports_complete: true,
			});
		},
	},
};
</script>
