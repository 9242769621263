export default {
	firestorePath: "appointments/{id}",
	firestoreRefType: "doc",
	moduleName: "appointment",
	statePropName: "data",
	namespaced: true,
	state: {},
	getters: {},
	serverChange: {
	},
	mutations: {},
	actions: {
	},
};
