import Vue from "vue";
import Vuex from "vuex";
import { createStore } from "vuex-extensions";
import VuexEasyFirestore from "vuex-easy-firestore";
Vue.use(Vuex);

import state from "./state";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

import firebase from "firebase";
import auth from "./modules/auth.js";
import emails from "./modules/emails.js";
import users from "./modules/users.js";
import consents from "./modules/consents.js";
import content from "./modules/content.js";
import profileFieldValues from "./modules/profileFieldValues.js";
import surveyResponses from "./modules/surveyResponses.js";
import surveys from "./modules/surveys.js";
import questions from "./modules/questions.js";
import config from "./modules/config.js";
import videoRoom from "./modules/videoRoom.js";
import appointments from "./modules/appointments";
import appointmentParticipants from "./modules/appointmentParticipants";
import appointment from "./modules/appointment";
import appointmentParticipant from "./modules/appointmentParticipant";
import projects from "./modules/projects.js";
import participantAvailability from "./modules/participantAvailability";
import rewards from "./modules/rewards.js";

const vxf = VuexEasyFirestore(
	[
		appointments,
		appointmentParticipants,
		appointmentParticipant,
		appointment,
		auth,
		config,
		content,
		consents,
		emails,
		profileFieldValues,
		questions,
		rewards,
		surveys,
		surveyResponses,
		projects,
		participantAvailability,
		users,
		videoRoom,
	],
	{
		logging: true,
		FirebaseDependency: firebase,
	}
);

const mwstoreutils = {
	actions: {
		fetchByIds: ({ dispatch }, ids) => {
			if (!ids) {
				return;
			}
			ids.forEach((id) => {
				dispatch("fetchById", id);
			});
		},
		fetchByIdOr: ({ dispatch, rootState }, { roles, contacts }) => {
			if (!roles) {
				roles = rootState.roles;
			}
			if (!contacts) {
				contacts = [rootState.auth.data.id];
				let email = rootState.auth.data.email;
				if (email) {
					contacts.push(email);
				}
				let phone = rootState.auth.data.phone;
				if (phone) {
					contacts.push(phone);
				}
			}
			dispatch("fetchAndAdd", {
				clauses: { where: [[roles, "array-contains-any", contacts]] },
			});
		},
	},
};
const storeData = {
	actions,
	getters,
	mutations,
	state,
	plugins: [vxf],
	mixins: mwstoreutils,
};

const store = createStore(Vuex.Store, storeData);

export default store;
