import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);


const vuetify = new Vuetify({
	theme: {
		themes: {
			light: {
				lightest: "#f0f0f0",
				secondary: "#b0bec5",
				accent: "#009FE3",
				error: "#b71c1c",
				yellow: "#F9CB40",
				darkblue: "#22577A",
				lightblue: "#8EB8E5", 
				greyblue: "#718697",
				pink: "#F97068", 
				green: "#4E937A",
				primary: "#85bd5f",
			},
		},
	},
});
export default vuetify;
