<template>
	<v-sheet style="margin-bottom: 30px; margin-top: 60px;">
		<h3 class="text-center mt-12 text-h2 text-xs-h4 text-sm-h3 primary--text font-weight-lightest text-uppercase">
            {{text}}
        </h3>
		<!-- <p class="text-center mt-0 mb-12">
			<v-icon small color="primary" v-for="n in 3" :key="n">
				mdi-circle
			</v-icon>
		</p> -->
	</v-sheet>
</template>

<script>
export default {
    name: "FancyTitle",
    props: {
        text: {type: String}
    }
}
</script>
